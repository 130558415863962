import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../Css/ProductDetail.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ProductSection from './ProductSection';
import { FaFacebook, FaTwitter, FaInstagram, FaPlus, FaMinus } from "react-icons/fa";
import { FaCheckCircle, FaUndo, FaLock } from "react-icons/fa";

export default function ProductDetail() {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [imageURLs, setImageURLs] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const storedData = JSON.parse(localStorage.getItem("data"));
  const userId = storedData ? storedData.userId : null;
  const token = storedData ? storedData.token : null;
  const navigate = useNavigate();


  const shareOnFacebook = () => {
    const productURL = window.location.href;
    const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${productURL}`;
    window.open(facebookShareURL, "_blank");
  };

  const shareOnTwitter = () => {
    const productURL = window.location.href;
    const text = `Check out this product: ${product.product_name}`;
    const twitterShareURL = `https://twitter.com/intent/tweet?url=${productURL}&text=${encodeURIComponent(text)}`;
    window.open(twitterShareURL, "_blank");
  };

  const shareOnInstagram = () => {
    const productDetails = {
      name: product.product_name,
      price: product.discount ? product.sale_price : product.price,
      description: product.product_description,
      url: window.location.href,
    };
  
    // Here, we are creating a URL encoded message for users to copy
    const message = `Check out this product!\n\nName: ${productDetails.name}\nPrice: ₹${productDetails.price}\n\n${productDetails.description}\n\nLink: ${productDetails.url}`;
  
    // Open Instagram sharing intent
    const instagramShareURL = `https://instagram.com/share?text=${encodeURIComponent(message)}`;
    
    window.open(instagramShareURL, "_blank");
  };
  

  const buyNow = () => {
    const productDetails = {
      productId: id,
      productName: product.product_name,
      price: product.discount ? product.sale_price : product.price,
      quantity: quantity,
      mainImage: mainImage
    };
    navigate('/puja-checkout', { state: productDetails });
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const toProductDetailPage = async () => {
    if (userId) {
      try {
        await axios.post(
          `https://free.jeevansanskriti.com/api/v1/cart`,
          {
            user_id: userId,
            product_id: id,
            quantity: quantity
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setIsAddedToCart(true);
        alert("Product added to cart");
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Please login before adding product to cart");
    }
  };

  const fetchProduct = async () => {
    try {
      const { data } = await axios.get(
        `https://free.jeevansanskriti.com/api/v1/product/${id}`
      );
      const productData = data.data[0];
      const allImages = [productData.main_image, ...(productData.ImageURLs ? productData.ImageURLs.split(",") : [])];
      setProduct(productData);
      setImageURLs(allImages);
      setMainImage(productData.main_image);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [id]);

  const handleQuantityChange = (amount) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  return (
    <>
      <Header />
      <div className="rt-product-container">
        <div className="rt-product-gallery">
          <div className="rt-product-main-image">
            <img
              src={`https://free.jeevansanskriti.com/images/${mainImage}`}
              alt="Product"
            />
          </div>
          <div className="rt-product-thumbnails">
            {imageURLs.map((image, index) => (
              <img
                key={index}
                src={`https://free.jeevansanskriti.com/images/${image}`}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => setMainImage(image)}
                className={image === mainImage ? "rt-active" : ""}
              />
            ))}
          </div>
        </div>
        <div className="rt-product-info">
          <h1>{product.product_name}</h1>
          <div className="rt-product-price">
            {product.discount ? (
              <>
                <span className="rt-discounted-price">₹{product.sale_price}</span>
                <span className="rt-original-price">₹{product.price}</span>
              </>
            ) : (
              <span>₹{product.price}</span>
            )}
          </div>
          <div className="rt-product-description">
            <p>{product.product_description}</p>
          </div>

          {/* Quantity Selector */}
          <div className="rt-product-quantity">
            <span>Quantity:</span>
            <div className="rt-quantity-selector">
              <button onClick={() => handleQuantityChange(-1)}><FaMinus /></button>
              <input type="text" value={quantity} readOnly />
              <button onClick={() => handleQuantityChange(1)}><FaPlus /></button>
            </div>
          </div>

          <div className="rt-product-actions">
            <button
              className="rt-product-add-to-cart"
              onClick={toProductDetailPage}
              disabled={isAddedToCart}
            >
              {isAddedToCart ? "Added to Cart" : "Add to Cart"}
            </button>
            <button className="rt-product-buy-now" onClick={buyNow}>Buy Now</button>
          </div>
          <div className="rt-product-shipping-info">
            <ul>
              <li><FaCheckCircle className="rt-icon" /> Satisfaction Guaranteed</li>
              <li><FaUndo className="rt-icon" /> No Hassle Refunds</li>
              <li><FaLock className="rt-icon" /> Secure Payments</li>
            </ul>
          </div>
          <div className="rt-product-share">
            <span>Share:</span>
            <FaFacebook className="rt-share-icon" onClick={shareOnFacebook} />
            <FaTwitter className="rt-share-icon" onClick={shareOnTwitter} />
            <FaInstagram className="rt-share-icon" onClick={shareOnInstagram} />
          </div>
        </div>
      </div>
      <div className="similar-product">
        <ProductSection />
      </div>
      <Footer />
    </>
  );
}
